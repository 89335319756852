import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    APP_SHORT_NAME: 'ArtAMS', 
    APP_VERSION: process.env.VUE_APP_VERSION,
    //apiURL: 'http://10.3.5.68:3000/api/',
    apiURL: process.env.NODE_ENV === 'production' ? 'https://art.icac.tech/api' :  'https://art.icac.tech/api',
    hasCamera: false,
    token: null,
    label: {},
    message: {},
    permissions: null,
    user: {},
    auth: null,
    isLoading: 0,
    controller: null,
    view: null,
    languageCode: 'zh-Hant',
    snackBar: {
      show: false,
      y: 'bottom', //'top', 'bottom'
      x: 'right', //'left', 'right'
      type: 'success', //'success', 'info', 'error'
      mode: 'multi-line', //'multi-line', 'vertical'
      timeout: 6000,
      text: false
    },
    timeoutID: null,
    tokenTimeoutID: null,
    SessionExpireTime: null,
    idleKey: -1,

    menu: {
      drawer: true,
      value: 0,
      items: [
        {
          heading: null,
          items: [
            { title: 'Dashboard', icon: 'mdi-home', role: ['Guest', 'Staff', 'Admin'], page: '/Dashboard', disabled: false },
          ]
        },
        {
          heading: 'Actions',
          items: [
            { title: 'Borrow', icon: 'mdi-home-export-outline', role: ['Staff', 'Admin'], page: '/ToBorrow', disabled: true },
            { title: 'Return', icon: 'mdi-home-import-outline', role: ['Admin'], page: '/ToReturn', disabled: true },
          ]
        },
        {
          heading: 'Records',
          items: [
            { title: 'Calendar', icon: 'mdi-calendar', role: ['Guest', 'Staff', 'Admin'], page: '/Calendar', disabled: true },
            { title: 'Borrows', icon: 'mdi-clipboard-text', role: ['Admin'], page: '/Borrows', disabled: true },
            // { title: 'Maintenance', icon: 'mdi-hammer', page: '/Maintenance' },
            // { title: 'Transfer', icon: 'mdi-arrow-left-right-bold', page: '/Transfer' },
          ]
        },
        {
          heading: 'Settings',
          items: [
            { title: 'Assets', icon: 'mdi-toolbox', role: ['Admin'], page: '/Assets', disabled: false },
            { title: 'Users', icon: 'mdi-account-multiple', role: ['Admin'], page: '/Users', disabled: false },
          ]
        }
      ],
    }
  },
  mutations: {

  },
  actions: {

  }
})
