import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router.js';
import './mixin.js'
import store from './store.js'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import VueMeta from 'vue-meta'
// import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

import { MagicPluginLoader } from './helpers' // eslint-disable-line no-unused-vars

import { StreamBarcodeReader } from "vue-barcode-reader";
Vue.component('StreamBarcodeReader', StreamBarcodeReader)

import DatetimePicker from '@goldenm/vuetify-datetime-picker'
Vue.use(DatetimePicker)

Vue.use(VueMeta)

Vue.config.productionTip = false

const app = {
  vuetify,
  router,
  store,
  render: h => h(App)
}

window.Store = store;

MagicPluginLoader('axios', app)
MagicPluginLoader('axiosBG', app)

new Vue(app).$mount('#app')