import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      // light: {
      //   primary: '#ee44aa',
      //   secondary: '#424242',
      //   accent: '#82B1FF',
      //   error: '#FF5252',
      //   info: '#2196F3',
      //   success: '#4CAF50',
      //   warning: '#FFC107'
      // },
      light: {
        primary: '#38537a',
        secondary: '#a2b7cf',
        accent: '#b94f56',
        success: '#6fb071',
        error: '#ff8585',
        info: '#52aaf2',
        warning: '#ffce3b',
        background: '#ebeef2'
      },
    },
  },
});
