import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('./views/Login.vue')
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('./views/Dashboard.vue')
  },
  {
    path: '/Assets',
    name: 'Assets Categories',
    component: () => import('./views/AssetsCategories.vue')
  },
  {
    path: '/Assets/Details/:id',
    name: 'Assets',
    component: () => import('./views/Assets.vue')
  },
  {
    path: '/Assets/Details',
    name: 'Assets',
    component: () => import('./views/Assets.vue')
  },
  {
    path: '/Borrows',
    name: 'Borrows',
    component: () => import('./views/Borrows.vue')
  },
  {
    path: '/Borrows/Details/:id',
    name: 'Borrows Details',
    component: () => import('./views/BorrowsDetails.vue')
  },
  {
    path: '/Borrows/Details',
    name: 'Borrows Details',
    component: () => import('./views/BorrowsDetails.vue')
  },
  {
    path: '/Users',
    name: 'Users',
    component: () => import('./views/Users.vue')
  },
  {
    path: '/Calendar',
    name: 'Calendar',
    component: () => import('./views/Calendar.vue')
  },
  {
    path: '/ToBorrow',
    name: 'To Borrow',
    component: () => import('./views/BorrowAction.vue')
  },
  // { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
