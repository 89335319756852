import Vue from 'vue'
var moment = require('moment-timezone');

Vue.mixin({
	data: function () {
		return {
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			if(!vm.$store.state.auth && to.path != "/Login" && to.path != "/"){
				vm.$router.push('/')
			}
		})
	},
	mounted() {
	},
	computed: {
		APP_SHORT_NAME: function () {
			return this.$store.state.APP_SHORT_NAME;
		},
		APP_VERSION: function () {
			return this.$store.state.APP_VERSION
		},
		isPermissionsNull: function () {
			return this.$store.state.permissions == null ? true : false;
		},
		// permissions: function () {
		// 	return this.$store.state.permission;
		// },
		langLabel: function () {
			return this.$store.state.label;
		},
		langMessage: function () {
			return this.$store.state.message;
		},
		token: function () {
			// if (this.$store == null) return null;
			// return this.$store.state.token;

			return this.getCookie("token");
		},
		user: function () {
			return this.$store.state.user;
		},
		apiURL: function () {
			return this.$store.state.apiURL;
		},
		hasCamera: function () {
			return this.$store.state.hasCamera;
		},
		// isLoading: {
		// 	get: function () {
		// 		return this.$store.state.isLoading;
		// 	},
		// 	set: function (value) {
		// 		this.$store.state.isLoading = value;
		// 	}
		// },
		auth: {
			get: function () {
				return this.$store.state.auth;
			},
			set: function (value) {
				this.$store.state.auth = value;
			}
		},
		menu: {
			get: function () {
				return this.$store.state.menu;
			},
			set: function (value) {
				this.$store.state.menu = value;
			}
		},
	},
	methods: {
		/* DateTime Format */
		formatD: function(dt){
			if(!dt) return null;
			// return moment.tz(dt, 'Asia/Hong_Kong').format("YYYY-MM-DD");
			return moment(dt).tz('Asia/Hong_Kong').format("YYYY-MM-DD");
		},
		formatDT: function(dt){
			if(!dt) return null;
			// return moment.tz(dt, 'Asia/Hong_Kong').format("YYYY-MM-DD HH:mm");
			return moment(dt).tz('Asia/Hong_Kong').format("YYYY-MM-DD HH:mm");
		},
		formatToUTC: function(dt){
			if(!dt) return null;
			return moment.tz(dt, "Asia/Hong_Kong").tz("UTC").format();
		},

		/* Cookie */
		setCookie(name, value, days) {
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
				expires = "; expires=" + date.toUTCString();
			}
			document.cookie = name + "=" + (value || "") + expires + "; path=/";
		},
		getCookie(name) {
			var nameEQ = name + "=";
			var ca = document.cookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
			}
			return null;
		},
		eraseCookie(name) {
			document.cookie = name + '=; Max-Age=-99999999;';
		},

		/* ajax */
		get: function (url, successfulCallback, failedCallback, {showSuccess = false, showError = true, showLoading = true}={}) {
			var _this = this;
			var _axios = this.axios;
			if(!showLoading) _axios = this.axiosBG;

			_axios.get(this.apiURL + url).then(res => {
				if (showSuccess) {
					_this.$store.state.snackBar = {
						show: true, type: 'success', text: res['data']['data']['message']
					};
				}
				if (successfulCallback) successfulCallback(res);
			}).catch(res => {
				if (showError) {
					_this.$store.state.snackBar = {
						show: true, type: 'error', text: res['data']['data']['message']
					};
				}
				if (res.status == 401) {
					_this.reset();
				}
				if (failedCallback) failedCallback(res);
			});
		},
		post: function (url, data, successfulCallback, failedCallback, {showSuccess = false, showError = true, showLoading = true}={}) {
			var _this = this;
			var _axios = this.axios;
			if(!showLoading) _axios = this.axiosBG;

			console.log(this.apiURL + url);

			_axios.post(this.apiURL + url, data).then(res => {
				if (showSuccess) {
					_this.$store.state.snackBar = {
						show: true, type: 'success', text: res['data']['data']['message']
					};
				}
				if (successfulCallback) successfulCallback(res);
			}).catch(res => {
				console.log(res)
				if (showError) {
					var message = res['data']['data']['message'];
					if (Array.isArray(message)){
						message = message.join('<br/>');
					}
					_this.$store.state.snackBar = {
						show: true, type: 'error', text: message
					};
				}
				if (res.status == 401) {
					_this.reset();
				}
				if (failedCallback) failedCallback(res);
			});
		},
		reset: function(){
			window.localStorage.removeItem("auth");
			this.eraseCookie("token");
			this.loginForm.valid = false;
			this.loginForm.model.username = null;
			this.loginForm.model.password = null;
			this.loginForm.showPassword = false;
			this.auth = null;
			this.sidebarNavValue = 0;
			delete this.axios.defaults.headers.common['Authorization'];
			delete this.axiosBG.defaults.headers.common['Authorization'];
		},


		getControllerName: function () {
			if (this.controller == null || this.controller == "") return null;

			for (let i = 0; i < this.menus.length; i++) {
				if (this.menus[i].controller == this.controller) {
					return this.menus[i].label;
				}
			}
			return null;
		},
		getViewName: function () {
			if (this.view == null || this.view == "") return null;
			for (let i = 0; i < this.menus.length; i++) {
				if (this.menus[i].controller == this.controller) {
					if (this.menus[i].views == null) continue;

					for (let j = 0; j < this.menus[i].views.length; j++) {
						if (this.menus[i].views[j].view == this.view) {
							return this.menus[i].views[j].label;
						}
					}
					return null;
				}
			}
			return null;
		},
		hasPermission(pc, mc, ac) {
			if (this.$store.state.permissions == null) return false;
			for (let i = 0; i < this.$store.state.permissions.length; i++) {
				if (this.$store.state.permissions[i].PrimaryCode == pc && this.$store.state.permissions[i].ModuleCode == mc && this.$store.state.permissions[i].ActionCode == ac)
					return true;
			}
			return false;
		},
		hasListPermission(pc) {
			if (this.$store.state.permissions == null) return false;
			for (let i = 0; i < this.$store.state.permissions.length; i++) {
				if (this.$store.state.permissions[i].PrimaryCode == pc)
					return true;
			}
			return false;
		},
		getLanguage: function () {
			var _this = this;

			this.getAjax("/Language/GetAll",
				function (result) {
					if (result.status == 200) {
						_this.$store.state.label = result.data.Label;
						_this.$store.state.message = result.data.Message;
						_this.$forceUpdate();
					}
				},
				function (result) {
					console.log(result);
				}
			);
		},
		getSessionExpireTime: function () {
			var _this = this;

			this.getAjax("/User/GetSessionExpireTime",
				function (result) {
					if (result.status == 200) {
						_this.$store.state.SessionExpireTime = result.data.SessionExpireTime;
					}
				},
				function (result) {
					console.log(result);
				}
			);
		},
		getUserPermissions: function () {
			var _this = this;

			this.getAjax("/User/GetUserPermissions",
				function (result) {
					if (result.status == 200) {
						_this.$store.state.permissions = result.data.Permissions;
						_this.$forceUpdate();
					}
				},
				function (result) {
					console.log(result);
				}
			);
		},
		getUserPermissionsBG: function () {
			var _this = this;

			this.getAjaxBG("/User/GetUserPermissions",
				function (result) {
					if (result.status == 200) {
						_this.$store.state.permissions = result.data.Permissions;
						_this.$forceUpdate();
					}
				},
				function (result) {
					console.log(result);
				}
			);
		},
		getRefreshToken: function () {
			var _this = this;

			this.getAjaxBG("/User/GetUserInfo",
				function (result) {
					if (result.status == 200) {
						_this.$store.state.user = result.data.User;
						// _this.$store.state.token = result.data.Token;
						_this.setCookie("token", result.data.Token, 20 / 60 / 24);
						_this.axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.Token}`;
						_this.axiosBG.defaults.headers.common['Authorization'] = `Bearer ${result.data.Token}`;
						_this.getUserPermissionsBG();
					}
					console.log(result.data);
				},
				function (result) {
					console.log(result);
				}
			);
		},
		updateToken: function () {
			var _this = this;
			_this.$store.state.tokenTimeoutID = setInterval(function () {
				console.log("The user is " + _this.$store.state.user.Username);
				if (_this.countObj(_this.$store.state.user) > 0) {
					_this.getRefreshToken();
				}
				else {
					clearInterval(_this.$store.state.tokenTimeoutID);
				}
			}.bind(_this), 1 * 60 * 1000);
		},
		logout() {
			this.setCookie("token", "", -9999);
			this.eraseCookie("token");
			this.$store.state.permissions = null;
			// this.$store.state.token = null;
			this.$store.state.auth = null;
			localStorage['auth'] = '';
			window.clearTimeout(this.$store.state.timeoutID);
			this.$store.state.timeoutID = null;
			localStorage.removeItem("idleKey");

			
			this.axios.defaults.headers.common['Authorization'] = "";
			this.axiosBG.defaults.headers.common['Authorization'] = "";
			this.navTo('/');
		},

		//User idle detection		
		checkUserIdle() {
			document.addEventListener("mousemove", this.resetCheckUserIdleTimer, false);
			document.addEventListener("mousedown", this.resetCheckUserIdleTimer, false);
			document.addEventListener("keypress", this.resetCheckUserIdleTimer, false);
			document.addEventListener("DOMMouseScroll", this.resetCheckUserIdleTimer, false);
			document.addEventListener("mousewheel", this.resetCheckUserIdleTimer, false);
			document.addEventListener("touchmove", this.resetCheckUserIdleTimer, false);
			document.addEventListener("MSPointerMove", this.resetCheckUserIdleTimer, false);
			this.startCheckUserIdleTimer();
		},
		startCheckUserIdleTimer() {
			var expireTime = parseInt(this.$store.state.SessionExpireTime);
			if (!isNaN(expireTime)) {
				this.$store.state.timeoutID = window.setTimeout(this.logout, expireTime * 60 * 1000);
			}
		},
		resetCheckUserIdleTimer() {
			var idleKey = parseInt(localStorage.getItem('idleKey'));
			if (isNaN(idleKey)) idleKey = 0;
			this.$store.state.idleKey = idleKey + 1;
			localStorage.setItem('idleKey', idleKey + 1);
			window.clearTimeout(this.$store.state.timeoutID);
			this.startCheckUserIdleTimer();
		},
		startLocalStorageListener() {
			let _this = this;
			window.addEventListener('storage', (event) => {
				if (event.key === "idleKey" && event.key != _this.$store.state.idleKey && _this.$store.state.timeoutID != null) {
					_this.resetCheckUserIdleTimer();
				}
			})
		},
		//User idle detection

		getAjaxBG: function (routeUrl, onSuccess, onError) {
			this.axiosBG.get(this.apiURL + routeUrl)
				.then(onSuccess)
				.catch(onError);
		},
		getAjax: function (routeUrl, onSuccess, onError) {
			this.axios.get(this.apiURL + routeUrl)
				.then(onSuccess)
				.catch(onError);
		},
		postAjax: function (routeUrl, data, onSuccess, onError, onNext) {
			this.axios.post(this.apiURL + routeUrl, data)
				.then(onSuccess)
				.catch(onError)
				.then(onNext);
		},
		showSuccessMessage(message) {
			this.$store.state.snackBar = {
				show: true,
				y: 'bottom', //'top', 'bottom'
				x: 'right', //'left', 'right'
				color: 'success', //'success', 'info', 'error'
				mode: 'multi-line', //'multi-line', 'vertical'
				timeout: 6000,
				snackbarText: message
			}
		},
		showErrorMessage(message) {
			this.$store.state.snackBar = {
				show: true,
				y: 'bottom', //'top', 'bottom'
				x: 'right', //'left', 'right'
				color: 'error', //'success', 'info', 'error'
				mode: 'multi-line', //'multi-line', 'vertical'
				timeout: 0,
				snackbarText: message
			}
		},
		navTo(path) {
			//this.getUserPermissionsBG();
			if(path == this.$route.path) return;
			this.$router.push(path);
		},
	}
})