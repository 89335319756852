<template>
  <div id="app">
    <v-app id="inspire" v-cloak :style="{background: $vuetify.theme.themes['light'].background}">
      <v-overlay :value="$store.state.isLoading" z-index="100">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      
      <v-app-bar app color="primary white--text">
        <v-app-bar-nav-icon v-if="auth" v-on:click.stop="menu.drawer = !menu.drawer" color="white"></v-app-bar-nav-icon>
        <v-toolbar-title>Common Production Assets Management System</v-toolbar-title>
      </v-app-bar>

      <v-navigation-drawer v-model="menu.drawer" v-if="auth" app class="text-left" height="100%">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{auth == null || auth == {} ? "" : auth.user.name}}</v-list-item-title>
            <v-list-item-subtitle>{{auth == null || auth == {} ? "" : (auth.user == null ? "" : auth.user.role.name)}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list nav dense>
          <v-list-item-group v-model="menu.value" active-class="primary--text text--accent-4">
            <div v-for="section in menu.items" :key="section.heading">
              <div v-if="section.items.some(e => e.role.includes(auth.user.role.name))">
                <v-subheader v-if="section.heading">{{section.heading}}</v-subheader>
                <div v-for="item in section.items" :key="item.title">
                  <v-list-item v-if="item.role.includes(auth.user.role.name)" v-on:click="navTo(item.page)" :disabled="item.disabled">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <v-divider></v-divider>
              </div>
            </div>
          </v-list-item-group>
        </v-list>
        <template v-slot:append>
          <div class="pa-2 mt-8" >
            <v-btn block color="primary" v-on:click="logout">
              <v-icon class="mr-2">mdi-exit-to-app</v-icon>Logout
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <v-overlay :value="$store.state.isLoading > 0">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-snackbar v-model="$store.state.snackBar.show" :color="$store.state.snackBar.type" multi-line app top>
        <span v-html="$store.state.snackBar.text"></span>
        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" v-on:click="$store.state.snackBar.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      
      <v-main>
        <v-slide-x-transition hide-on-leave>
          <router-view/>
        </v-slide-x-transition>
      </v-main>

      <v-footer fixed style="background: transparent" class="text--disabled" v-if="APP_VERSION">
        <span class="caption">v.{{APP_VERSION}}</span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
var DetectRTC = require('detectrtc');

export default {
  name: 'App',
  data: function () {
    return {
    }
  },
  metaInfo() {
    return { 
      title: "Common Production Assets Management System",
      // meta: [
      //   { name: 'description', content:  'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'},
      //   { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
      //   { property: 'og:site_name', content: 'Epiloge'},
      //   {property: 'og:type', content: 'website'},    
      //   {name: 'robots', content: 'index,follow'} 
      // ]
    }
  },
  created() {
    var _this = this;
    if(localStorage['auth']){
      this.auth = JSON.parse(localStorage['auth']);
      this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.auth.access_token;
      this.axiosBG.defaults.headers.common['Authorization'] = 'Bearer ' + this.auth.access_token;
      localStorage['auth'] = JSON.stringify(this.auth);

      console.log('path', this.$route.path.split('/')[1]);
      
      var path = this.$route.path.split('/');
      if(!path[1] || path[1] == ''){
        this.menu.value = 0;
      }else{
        var idx = 0;
        this.menu.items.forEach(sections => {
          sections.items.forEach(item => {
            if(item.page == ('/' + path[1])){
              _this.menu.value = idx;
            }
            idx++;
          });
        });
      }

      // if(this.$route.path == "/Login" || this.$route.path == "/")
      //   this.navTo('/Dashboard')
    }else{
      this.navTo('/')
    }

    DetectRTC.load(function() {
      _this.$store.state.hasCamera = DetectRTC.hasWebcam;
    });
  },
  methods: {
  }
}
</script>

<style>
body {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
