import axios from 'axios'

export default ({ store, Vue }) => {
    const axiosB = axios.create();

    var getCookie = function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    if (getCookie("token")) {
        axiosB.defaults.headers.common['Authorization'] = `Bearer ${getCookie("token")}`;
    }

    if (store.state.token)
        axiosB.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;
    axiosB.defaults.headers.common['Globalization'] = store.state.languageCode;
    axiosB.defaults.headers.common['pragma'] = 'no-cache';

    // Add a request interceptor
    axiosB.interceptors.request.use(function (config) {
        // Do something before request is sent
        return config;
    }, function (error) {
        // Do something with request error
        console.log(error);
        return Promise.reject(error);
    });

    // Add a response interceptor
    axiosB.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
    }, function (error) {
        // Do something with response error
        return Promise.reject(error.response);
    });

    Vue.prototype.axiosBG = axiosB
}
